
import { defineComponent, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import moment from "moment/moment";
import store from "@/store";

export default defineComponent({
  name: "filterbox",
  props: {
    applied_filters: {
      type: Object,
      default: () => {
        return { tz: "", dateBetween: [] };
      },
    },
    show_tz: {
      type: Boolean,
      default: true,
    },
    show_cutoff: {
      type: Boolean,
      default: false,
    },
    show_id: {
      type: Boolean,
      default: false,
    },
    show_name: {
      type: Boolean,
      default: false,
    },
    show_phone: {
      type: Boolean,
      default: false,
    },
    show_email: {
      type: Boolean,
      default: false,
    },
    show_vertical: {
      type: Boolean,
      default: true,
    },
    show_publisher: {
      type: Boolean,
      default: true,
    },
    show_affiliate: {
      type: Boolean,
      default: true,
    },
    show_offer: {
      type: Boolean,
      default: true,
    },
    show_country: {
      type: Boolean,
      default: true,
    },
    show_callcenter: {
      type: Boolean,
      default: true,
    },
    show_date: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    showCallCenters() {
      return (
        this.callcenterList.length > 0 &&
        store.getters.isUserAuthenticated &&
        (store.getters.currentUser.moderator == 1 ||
          store.getters.hasPermission("callcenters.view"))
      );
    },
  },
  data() {
    return {
      shortcuts: [
        {
          text: "Today",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setHours(0, 0, 0);
            end.setHours(23, 59, 59);
            return [start, end];
          },
        },
        {
          text: "Yesterday",
          value: () => {
            const start = new Date();
            const end = new Date();
            console.log(start);
            console.log(start.getTime());

            start.setTime(start.getTime() - 3600 * 24 * 1000);
            start.setHours(0, 0, 0);
            end.setTime(end.getTime() - 3600 * 24 * 1000);
            end.setHours(23, 59, 59);
            console.log(start);
            console.log(end);
            return [start, end];
          },
        },
        {
          text: "Last 7 days",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "Last 30 Days",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "This Month",
          value: () => {
            const end = new Date();
            return [moment().startOf("month").toDate(), end];
          },
        },
        {
          text: "Last Month",
          value: () => {
            return [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ];
          },
        },
      ],
      showAffiliates: false,
      showPublishers: false,
      showManagers: false,
      statusOptions: {
        open: "Abierto",
        closed: "En tratamiento",
        processed: "Procesado",
      },
      filters: this.applied_filters,
    };
  },
  emits: ["changed"],
  methods: {
    managerChanged() {
      this.dataChanged();
      ApiService.query("affiliates", {
        params: { perPage: 9999, filters: this.applied_filters },
      }).then((response) => {
        this.affiliateList = response.data.data;
        this.showAffiliates = this.affiliateList.length > 1;
      });

      ApiService.query("publishers", {
        params: { perPage: 9999, filters: this.applied_filters },
      }).then((response) => {
        this.publisherList = response.data.data;
        this.showPublishers = this.publisherList.length > 0;
      });
    },
    verticalChanged() {
      this.dataChanged();

      ApiService.query("offer_list", {
        params: { perPage: 9999, filters: JSON.stringify(this.applied_filters) },
      }).then((response) => {
        this.offerList = response.data.data;
      });
    },
    affiliateChanged() {
      this.dataChanged();

      ApiService.query("publishers", {
        params: { perPage: 9999, filters: this.applied_filters },
      }).then((response) => {
        this.publisherList = response.data.data;
        this.showPublishers = this.publisherList.length > 0;
      });
    },
    dataChanged() {
      this.$emit("changed");
    },
  },
  setup() {
    let affiliateList = ref([]);
    let managerList = ref([]);
    let publisherList = ref([]);
    let offerList = ref([]);
    let countryList = ref([]);
    let verticalList = ref([]);
    let callcenterList = ref([]);
    let tzList = ref([]);

    return {
      managerList,
      callcenterList,
      affiliateList,
      publisherList,
      offerList,
      countryList,
      verticalList,
      tzList,
    };
  },
  mounted() {
    let timeZone = window.localStorage.getItem("timeZone");
    if (this.filters) {
      this.filters.tz = timeZone;

      watch(this.filters, () => {
        if (this.filters.tz) {
          window.localStorage.setItem("timeZone", this.filters.tz);
        }
      });
    }

    ApiService.query("affiliates", {
      params: { perPage: 9999, filters: JSON.stringify(this.applied_filters) },
    }).then((response) => {
      this.affiliateList = response.data.data;
      this.showAffiliates = this.affiliateList.length > 1;
    });

    ApiService.query("publishers", {
      params: { perPage: 9999, filters: JSON.stringify(this.applied_filters) },
    }).then((response) => {
      this.publisherList = response.data.data;
      this.showPublishers = this.publisherList.length > 0;
    });

    ApiService.query("managers", {
      params: { perPage: 9999, filters: JSON.stringify(this.applied_filters) },
    }).then((response) => {
      this.managerList = response.data.data;
      this.showManagers = this.managerList.length > 0;
    });

    ApiService.query("offer_list", {
      params: { perPage: 9999, filters: JSON.stringify(this.applied_filters) },
    }).then((response) => {
      this.offerList = response.data.data;
    });

    ApiService.query("verticals", {
      params: { perPage: 9999, filters: JSON.stringify(this.applied_filters) },
    }).then((response) => {
      this.verticalList = response.data.data;
      console.log(this.verticalList);
    });

    ApiService.query("countries", {
      params: { perPage: 9999 },
    }).then((response) => {
      this.countryList = response.data.data;
      console.log(this.countryList);
    });

    ApiService.query("timezones", {
      params: { perPage: 9999 },
    }).then((response) => {
      this.tzList = response.data.data;
    });

    ApiService.query("callcenters", {
      params: { perPage: 9999 },
    }).then((response) => {
      this.callcenterList = response.data.data;
    });
  },
});
