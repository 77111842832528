
import { defineComponent, reactive, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "entitytable",
  template: "#entitytable",
  components: {},
  emits: ["startLoading", "endLoading"],
  props: {
    filterData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    resourceName: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      required: false,
      default: '',
    },
    overrideColumns: {
      type: Array,
      required: false,
    },
    routePrefix: {
      type: String,
      required: true,
    },
    allowSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowSort: {
      type: Boolean,
      required: false,
      default: false,
    },
    linkColumnIndex: {
      type: Number,
      required: false,
      default: 1,
    },
    paginationLayout: {
      type: String,
      default: () => {
        return "prev, pager, next, sizes";
      },
    },
    showQuickfilter: Boolean,
    showCreate: Boolean,
    showExport: Boolean,
    showEdit: Boolean,
    showActions: Boolean,
    showDelete: Boolean,
    showTotals: Boolean,
    showPagination: Boolean,
      linkView: {
        type: Boolean,
        required: false,
        default: false,
      },
    entityName: String,
    widgetClasses: String,
  },

  data() {
    return {
      totals: {},
      filters: {
        string: "",
      },
      columnsData: reactive<any>([]),
      perPage: 20,
      sortBy: "grpby",
      sortDirection: "DESC",
      exportingData: "",
      entities: null,
      stringFilter: "",
      loading: false,
      page: 1,
      pages: [],
      count: 0,
      roles: {
        transport_manager: {
          name: "Transportista",
          icon: "media/icons/duotone/Shopping/Box3.svg",
        },
        transport_visitor: {
          name: "Visitador",
          icon: "media/icons/duotone/Clothes/Shirt.svg",
        },
      },
    };
  },
  methods: {
    sortByColumn(column_name) {
      if (this.sortBy == column_name) {
        this.sortDirection = this.sortDirection == "ASC" ? "DESC" : "ASC";
      } else {
        this.sortDirection = "ASC";
        this.sortBy = column_name;
      }
      this.retrieveData();
    },
    exportData() {
      this.exportingData = "on";
      ApiService.query("export/" + this.resourceName, {
        responseType: "blob",
        params: {
          filterString: this.filters.string,
        },
      }).then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "export.pdf";
        link.click();
        this.exportingData = "off";
      });
    },
    getStatusClass(status) {
      switch (status) {
        case "active":
          return "svg-icon-success";
        case "inactive":
          return "svg-icon-danger";
      }
    },
    triggerSearch(instant) {
      setTimeout(
        () => {
          this.page = 1;
          this.filters.string = this.stringFilter;
          this.retrieveData();
        },
        instant ? 10 : 400
      );
    },
    gotoPage(pageNumber) {
      this.page = pageNumber;
      this.retrieveData();
    },
    handleDelete(id) {
      ApiService.delete(this.resourceName, id.toString()).then(() => {
        this.retrieveData();
      });
    },
    resetPage() {
      this.page = 1;
    },
    downloadData() {
      ApiService.downloadQuery(this.resourceName, {
        params: {
          perPage: this.perPage,
          page: this.page,
          filters: JSON.stringify(this.filterData),
          filterString: this.filters.string,
          sortBy: this.sortBy,
          sortDirection: this.sortDirection,
          download: 1,
        },
      }).then((response) => {
        console.log(response);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.click();
      });
    },
    retrieveData() {

      this.loading = true;
      this.$emit("startLoading");

      ApiService.query(this.resourceName, {
        signal: ApiService.signal,
        params: {
          perPage: this.perPage,
          page: this.page,
          filters: JSON.stringify(this.filterData),
          filterString: this.filters.string,
          sortBy: this.sortBy,
          sortDirection: this.sortDirection,
        },
      })
        .then((response) => {
          this.loading = false;
          this.$emit("endLoading");

          if (response.data.columns) {
            this.columnsData = response.data.columns;
          } else {
            this.columnsData = this.overrideColumns;
          }
          if (response.data.totals) {
            this.totals = response.data.totals;
          }

          this.page = response.data.meta.current_page;
          this.entities = response.data.data;
          this.count = response.data.meta.total;
        })
        .catch(() => {
          this.$emit("endLoading");
          this.loading = false;
        });
    },
  },
  mounted() {
    this.retrieveData();
    watch(reactive(this.filterData), () => {
      this.retrieveData();
    });
  },
  setup() {
    const store = useStore();
    return { store };
  },
});
